@import "./reset.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:not(:is([class*="ub-"], [class*="-md-editor"])) {
  flex-wrap: wrap;
}

:is(form, table) :is(select, input) {
  max-width: 60vw;
}

@mixin input-and-select-width {
  min-width: 12ch;
}

input {
  &[type="number"] {
    max-width: 24ch;
  }
  &:not(:is([type="checkbox"], [type="radio"], [type^="date"])) {
    @include input-and-select-width();
  }

  &[type="date"] {
    min-width: 18ch;
    max-width: 20ch;
  }
  &[type="datetime-local"] {
    min-width: 18ch;
    max-width: 30ch;
  }
}

select {
  @include input-and-select-width();

  &:empty,
  &:not(:has(:not(:empty))) {
    display: none;
  }
}

.number-cell {
  justify-content: flex-end;
}

@media print, not screen {
  embed,
  nav,
  footer {
    display: none !important;
  }
}

@media print {
  @page {
    size: auto;
    margin: 10mm;
  }

  body,
  body * {
    print-color-adjust: exact;
    filter: grayscale(100%);
    margin: 0;
    padding: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
    padding: 0;

    :is(tr, td) {
      page-break-inside: avoid;
      vertical-align: top;
    }

    td {
      page-break-after: auto;
    }
  }
}

@media screen and (max-width: 768px) {
  .not_mobile {
    display: none !important;
  }
}
