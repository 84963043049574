@mixin menu-position {
  position: sticky;
  left: 0;
  top: 0;
  line-height: 2.5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: center;
  z-index: 4;
  flex-wrap: wrap;
}

.App {
  & > nav nav {
    @include menu-position();
  }
  & > nav {
    @include menu-position();
    padding: 15px;

    & > * {
      width: 100%;
    }

    a {
      margin: 0;
      font-size: 1.2em;
    }
  }

  @media screen and (min-width: 769px) {
    .desktop nav {
      display: flex;
      flex-direction: row;
    }
  }

  @media screen and (max-width: 768px) {
    .mobile nav {
      flex-direction: column;
    }

    details {
      &[open] summary::marker {
        content: "X\00a0";
      }

      &:not([open]) summary::marker {
        content: "\2630\00a0";
      }
    }

    nav[class^="ub-"] > ul[class^="ub-"] > li[class^="ub-"] {
      & > span {
        visibility: collapse;
      }

      &:has(> span) {
        display: contents;
      }
    }
  }
}
