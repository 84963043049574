@mixin clickable-font {
  font-weight: bolder;
  cursor: pointer;
  user-select: none;
}

@mixin clickable-hover {
  opacity: 0.5;
}
:root {
  &:has(.light) {
    --document_background: AntiqueWhite;
    --document_font_color: black;
    --document_footer: cornflowerblue;
    --document_link: black;
    --document_link_hover: Gray;
    --document_content_background: #e9e9f1;
    --document_active: Peru;

    --even-row: AliceBlue;
    --odd-row: BurlyWood;
  }

  &:has(.dark) {
    --document_background: #05445e;
    --document_font_color: #f2f2f2;
    --document_footer: #2f3645;
    --document_link: #189ab4;
    --document_link_hover: #e6754b;
    --document_content_background: #033c47;
    --document_active: #d4f1f4;

    --even-row: #444444;
    --odd-row: #333333;
  }
}

* {
  max-width: 100vw;
  text-wrap: wrap;
}

.error {
  color: red !important;
}

.dangerous {
  background-color: red;
}

button,
input[type="submit"],
input[type="button"] {
  overflow-wrap: break-word;
  color: var(--document_font_color) !important;
  padding: 10px 20px;
  cursor: pointer;
}

button,
input,
select:not([multiple]) {
  border-radius: 75px;
  margin: 2px;

  &:disabled {
    filter: grayscale(1);
    cursor: not-allowed;
  }

  &:focus-visible {
    outline: 2px solid dodgerblue;
  }
}

*:not(:is([class*="ub-"], svg, path, circle)),
[class*="fa-"],
[class*="fa-"] * {
  color: var(--document_font_color);
}

details {
  text-align: center;
}

#root {
  #content {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 2.5em;
    td,
    div {
      text-align: left;
    }
    h1,
    footer {
      text-align: center;
    }

    td,
    div {
      :has(input[type="submit"]) {
        text-align: center;
      }
    }

    form input {
      break-after: always;
    }

    div {
      &:has(:is(form, table)) {
        margin: auto;
      }

      &[class^="ub-"]:has(> span.clickable:only-child):has(svg) {
        display: flex;
        justify-content: center;
      }

      &.ub-bg-clr_white > div,
      &.ub-bg-clr_white > a > div {
        padding: 12px;
      }
    }

    select {
      background-color: white !important;
    }

    *[class^="ub-"]:has(:is(select, input, .fa-download)):not(.ub-h_32px),
    :is(input, select)[class^="ub-"]:not(:is([type^="date"], .ub-h_32px)) {
      height: auto;
    }

    *[class^="ub-"]:has(:is(select, input, .fa-download)):not(:is(div, :has(circle))),
    form > div[class^="ub-"]:not(:has(circle)) {
      width: 100%;
    }

    input[class^="ub-"]:not([type^="date"]),
    div[class^="ub-"]:has(> input[class^="ub-"]:not([type^="date"])) {
      width: 98%;
    }

    *:not(:has(embed)) form:has(div[class^="ub-"]) > *:only-child {
      margin: auto;
    }

    td *:only-child:not(:is([type="checkbox"], [type="radio"], [type^="date"])),
    select {
      width: max(95%, auto);
      display: flex;
      justify-content: center;
    }
  }

  caption {
    overflow-wrap: break-word;
  }

  footer,
  nav {
    background-color: var(--document_footer);
    color: var(--document_font_color);
  }

  footer {
    display: flex;
    align-items: center;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2.5em;
    justify-content: center;
    z-index: 3;

    & * {
      color: var(--document_font_color);
      margin: 2px;
      padding: 1px;
    }
  }

  a {
    @include clickable-font();
    text-decoration: none;
    color: var(--document_link);
    margin: 2px;
    padding: 1px;

    &:hover {
      color: var(--document_link_hover);
    }

    &:hover,
    &:has(:hover) *,
    &:hover * {
      @include clickable-hover();
    }

    * {
      font-weight: bolder;
    }
  }

  .clickable {
    @include clickable-font();
    &:hover {
      @include clickable-hover();
    }
  }

  td {
    font-weight: normal;

    &:has(:is(input, button)[type="submit"]) {
      text-align: center !important;
    }
  }

  th {
    word-break: keep-all;

    &::first-letter {
      text-transform: capitalize;
    }
  }

  summary {
    color: var(--document_link);
  }

  li {
    padding: 5px;
  }

  table,
  tr,
  th,
  td {
    border: solid black 2px;
    border-collapse: collapse;
  }

  caption ~ tr th {
    border: solid black 2px !important;
  }

  article {
    margin: 10px;
    & > h1 {
      font-size: 2.5em;
    }

    &:first-of-type(p) {
      font-size: 1.5em;
    }
  }

  label {
    word-break: normal;
  }

  nav[class^="ub-"]:not(:last-of-type) {
    margin-top: 15px;
  }

  :is(div.ub-bg_wivy90 div, div.ub-bg-clr_white, div.ub-bg-clr_white div):not(
      :has(svg:not([class*="fa-"]) :is(path, circle))
    ) {
    word-break: normal;
    overflow: visible !important;
    height: auto;
  }

  a > div[class^="ub-"] {
    height: auto;
  }
}

.limit_98percent {
  max-width: 98%;
  * {
    max-width: 100%;
  }
}
.wide {
  min-width: 80vw !important;
}

nav[class^="ub-"] > ul[class^="ub-"] > li[class^="ub-"] * {
  color: var(--document_font_color) !important;
  fill: unset;
}

html,
body,
#content,
#root {
  background: var(--document_background);
}

@media screen and (max-width: 768px) {
  .desktop {
    display: none !important;
  }

  #logo {
    height: min(65vw, 50vh);
    width: min(65vw, 50vh);
  }

  *:not(:has(embed)) form:has(div[class*="ub-"]) {
    width: 100%;
  }
}

@media screen and (min-width: 769px) {
  .mobile {
    display: none !important;
  }

  #logo {
    height: min(50vw, 50vh);
    width: min(50vw, 50vh);
  }

  *:not(:has(embed)) form:has(div[class*="ub-"]):has(:is(input, select, textarea, button)) {
    min-width: 35vw;
    margin: auto;
  }

  embed {
    width: 50vw;
    margin-top: 1.25em;
    height: calc(100% - 3.5em);
  }
  .desktop-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
